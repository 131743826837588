import Portfolio from '../Portfolio/Portfolio';
import './aboutme.css';

function AboutMe() {
    return (
        <section className="aboutme" id="aboutme">
            <h2 className="aboutme__header">Студент</h2>
            <div className="aboutme__card-container">
                <div className="aboutme__text-container">
                    <p className="aboutme__name">Павел</p>
                    <p className="aboutme__description">JavaScript-разработчик, 32 года</p>
                    <p className="aboutme__text">Я родился в Москве, закончил факультет прикладной математики Московского Авиационного Института (МАИ).
                    Со студенческих лет заинтересовался сферой IT, но раньше почти не работал в ней, за исключением пары фриланс-заказов сайтов на WordPress.
                    Прошел курс Веб-разработка в Яндекс.Практикуме, открыл для себя интересные и современные инструменты и продолжаю развиваться в этой области.
                    Увлекаюсь музыкой, фотографией, коллекционирую пластинки, играю в шахматы, люблю спорт.</p>
                    <a className="aboutme__link" rel="noreferrer" target="_blank" href="https://github.com/pavlogorbunov">Github</a>
                </div>
                <img className="aboutme__portrait" alt="Портрет" src="https://images.unsplash.com/photo-1669542872916-cd696767d4f7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3174&q=80" />
            </div>
            <Portfolio />
        </section>
    )
}

export default AboutMe;