// import { Link } from "react-router-dom";
import NavTab from '../NavTab/NavTab';
import './promo.css';

function Promo() {
    return (
        <section className="promo">
            <h1 className="promo__header">Movies Explorer</h1>
            <h2 className="promo__subheader">Учебный проект Яндекс.Парктикум x BeatFilm</h2>
            <NavTab />
        </section>
    )
}

export default Promo;